import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/snippet-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve explored a number of interests in my short time on this planet. In high school I focused on music and hoped to become a composer. In college, I explored psychology and graphic design. After college, I worked with a Web Developer who inspired me to learn more about the web. Before long, I landed a career as a web designer and developer in a university staff position. I was promoted to manage a department of creative and technical professionals that helped professors teach well and use technology effectively.`}</p>
    <p>{`Now fully enveloped in building beautiful and usable web applications, I completed an M.F.A. in Interactive Design and Game Development from the Savannah College of Art and Design and transitioned to teach full time at Cedarville University. In this role I had the fantastic opportunity to make a difference in the development of budding professionals, use my skills in design thinking, instructional design, and leadership to improve University operations, and build an expertise in front end development crafting digital interfaces for sites and web applications. Seeking to deepen my software consulting and product development experience I left Academia in 2017. Since then I have worked at a startup, a government contractor, a fully remote digital agency, and prominent tech platform. Today I am all the more passionate about bridging between Design and Engineering to build delightful user experiences with precise yet scalable code.`}</p>
    <p>{`I live in Charleston, South Carolina with my beautiful wife, two kids, and loyal golden doodle. I enjoy hiking and backpacking, `}<a parentName="p" {...{
        "href": "/music"
      }}>{`arranging and orchestration`}</a>{`, and discovering good eats along the way.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      