import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Bio from "./bio.md";

const badges = [
  {
    source: "github",
    url: "https://github.com/philschanely",
    description: "View source for these and other projects on Github."
  },
  {
    source: "codepen",
    url: "https://codepen.io/philschanely/",
    description: "See some of my smaller doodles on Codepen."
  }
]

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <header>
      <div class="content">
        <h1>Phil Schanely <small>Interactive Design and Web Development</small></h1>
        <p class="lead">Welcome! I’m glad you’ve stopped by. Here you can see some of my work, learn a little more about me, and find some ways to get in touch.</p>
      </div>
      <img src="images/phil-circle-2017.png" alt="Phil" />
    </header>
    <section class="work">
      <h2>Some of my work</h2>
      <ul>
        {data.works.edges.map(({ node }, i) => {
          return (
            <li key={i}>
              <Link to={node.fields.slug}>
                <h3>{node.frontmatter.title}</h3>
                <p>{node.frontmatter.intro}</p>
                <div className="work__figure">
                  <img src={node.frontmatter.thumb} alt="" />
                </div>
              </Link>
            </li>
          );
        })}
        {badges.map(({ source, url, description }, i) => (
          <li class="badge" key={i}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <i class={`fab fa-${source} fa-5x`} />
              <p>{description}</p>
            </a>
          </li>
        ))}
      </ul>
    </section>
    <section class="bio">
      <h2>A little about me</h2>
      <div class="journey">
        <Bio />
      </div>
      <div class="now">
        <img src="images/phil-sitting.png" alt="" />
      </div>
    </section>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query siteIndex {
    works: allMdx(filter: {frontmatter: {published: {eq: true}}}, sort: {fields: frontmatter___number}) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            number
            intro
            thumb
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
